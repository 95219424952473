$(document).on("change", "#location-types", function (e) {
    $("#location-subtype").prop("disabled", false)
    $("#location-subtype").empty();
    $("#location-subtype").append(
        '<option value="">Loading....</option>'
      );
    var parent_id = $(this).val(); // Get the selected parent ID
  
    $.ajax({
      url: myAjax.ajaxurl, // In WordPress, 'ajaxurl' is predefined
      type: "POST",
      data: {
        action: "fetch_child_locations", // Custom action name defined in PHP
        parent_id: parent_id, // Pass the selected parent ID
      },
      success: function (response) {
        console.log(response);
        if (response.success) {
          console.log("Child Locations:", response.data); // Display child terms in console or process them
          $("#location-subtype").empty(); // Clear any previous options
          $("#location-subtype").append(
            '<option value="">Select County</option>'
          );
          // Populate the child-select dropdown with the received terms
          $.each(response.data, function (index, term) {
            $("#location-subtype").append(
              '<option value="' + term.slug + '">' + term.name + "</option>"
            );
          });
        } else {
          $("#location-subtype").prop("disabled", true)
          console.log("Error:", response.data.message); // Handle errors
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error:", textStatus, errorThrown); // Handle AJAX errors
      },
    });
  });
  